<script lang="ts" setup>
const isOpen = defineModel<boolean>()

const { canAccessPage } = useAuthorization()
const configsToShow = computed(() => Object.entries(navigationConfigs).filter(config => (canAccessPage(config[1].link))))
</script>

<template>
  <div v-if="isOpen" class="fixed top-0 left-0 h-screen w-full bg-hos-blue-dark z-20">
    <div class="PagePadding mt-[67px] pt-5 overflow-y-auto h-[93vh]">
      <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-x-2 gap-5 md:gap-y-8">
        <CustomerModuleLayoutMegaMenuItem
          :title="$t('customerModule.navigation.start.title')"
          :description="$t('customerModule.navigation.start.description')"
          icon="material-symbols:garage-home-outline-rounded"
          @click="$router.push('/my-hos').then(() => isOpen = false)"
        />

        <CustomerModuleLayoutMegaMenuItem
          v-for="[name, config] of configsToShow"
          :key="name"
          :title="$t(`customerModule.navigation.${name}.title`)"
          :description="$t(`customerModule.navigation.${name}.description`)"
          :icon="config.icon"
          @click="$router.push(config.link).then(() => isOpen = false)"
        />
      </div>
    </div>
  </div>
</template>
